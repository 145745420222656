import {
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoInstagram,
    IoLogoGithub,
  } from 'react-icons/io';

 const IconOptions: { [key: string]: (props: any) => any } = {
  facebook: IoLogoFacebook,
  instagram: IoLogoInstagram,
  github: IoLogoGithub,
  twitter: IoLogoTwitter,
};

export default IconOptions;
