import React from 'react';
import  { FluidImage } from '../gatsby-image';
import SocialProfile from '../social-profile/social-profile';
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Desciption,
  IntroInfo,
} from './style';
import withSbEditable from '../withSBeditable/withSBeditable';
import IconOptions from "../IconOptions";

type IntroProps = {
  name: string;
  description: string;
  lead: string;
  picSize: "small" | "medium" | "large";
  banner_image: SFAsset;
  contacts: {
    link: SFLink;
    name: string;
    icon: string;
  }[];
};


const Intro: React.FunctionComponent<IntroProps> = (props) => {

  const { name, description, lead, picSize, banner_image, contacts } = props;


  const contactItems =  contacts?.map(contact => {
    const Icon = IconOptions[contact?.icon?.toLowerCase()]
    return ({
      icon: <Icon />,
      url: contact?.link?.url,
      toottip: contact?.name,
    })
  })

  return (
    <IntroWrapper>
      <IntroImage picSize={picSize}>
        <FluidImage blok={banner_image} alt={banner_image?.alt || name} />
      </IntroImage>
      <IntroInfo>
        <IntroTitle>
          {lead} <b>{name}</b>
        </IntroTitle>
        <Desciption>{description}</Desciption>
        <SocialProfile items={contactItems} />
      </IntroInfo>
    </IntroWrapper>
  );
};

export default withSbEditable(Intro);
